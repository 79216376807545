/* eslint-disable eqeqeq */
const en = {
  languageData: {
    plurals: function (n, ord) {
      var s = String(n).split('.'),
        v0 = !s[1],
        t0 = Number(s[0]) == n,
        n10 = t0 && s[0].slice(-1),
        n100 = t0 && s[0].slice(-2)
      if (ord)
        return n10 == 1 && n100 != 11
          ? 'one'
          : n10 == 2 && n100 != 12
          ? 'two'
          : n10 == 3 && n100 != 13
          ? 'few'
          : 'other'
      return n == 1 && v0 ? 'one' : 'other'
    },
  },
  messages: {
    '(demais parcelas)': '(other installments)',
    '(dias de repasse da 1\xB0 parcela)': '(days of transfer of the 1st installment)',
    '- N\xE3o informado': 'Uninformed',
    'A receber': 'Receiving value',
    'A senha deve conter no m\xEDnimo 6 (seis) caracteres': 'Password must be at least 6 characters',
    'Acesso Restrito': 'Restricted access',
    Afastamento: 'Clearance',
    Agendado: 'Scheduled',
    Agência: 'Banking Agency',
    Analisando: 'Analyzing',
    'Antecipa\xE7\xE3o de Receb\xEDveis': 'Receivable advance',
    'Ao vincular um Marketplace, esta conta n\xE3o poder\xE1 ser alterada no futuro.':
      'When linking a Marketplace, this account cannot be changed in the future.',
    'Ao vincular um Seller, esta conta n\xE3o poder\xE1 ser alterada no futuro.':
      'When linking a Seller, this account cannot be changed in the future.',
    Aprovado: 'Approved',
    'Período de recebimento': 'Receipt period',
    'As senhas devem ser iguais': 'Passwords must be the same',
    Ativo: 'Active',
    'Autorization ID': 'Autorization ID',
    Bairro: 'Neighborhood/suburb/area',
    Banco: 'Bank',
    'Banco Cedente': 'Seller Bank',
    'Bloqueado + Pendente': 'Blocked + Pending',
    CEP: 'Zip code',
    CNES: 'CNES',
    CNPJ: 'Document Number',
    CRM: 'CRM',
    'Campos adicionais': 'Additional fields',
    Cancelado: 'Canceled',
    'Carregando...': 'Loading...',
    Carteira: 'Wallet',
    Cedente: 'Seller Bank',
    Cedentes: 'Sellers Banks',
    'Chave Maxipago': 'Maxipago Key',
    Cidade: 'City',
    'Como os clientes v\xE3o comprar nesta loja?': 'How will customers shop at this store?',
    Complemento: 'Address line 2',
    Conta: 'Account',
    'Conta Corrente': 'Checking Account',
    'Conta Deposito': 'Deposit Account',
    'Conta Pagamento': 'Payment Account',
    'Conta Poupan\xE7a': 'Savings account',
    'Conta corrente': 'Checking account',
    Contato: 'Contact',
    Convênio: 'Covenants',
    Creditor: 'Creditor',
    Credores: 'Creditors',
    'Criar novo creditor': 'Create new creditor',
    'C\xF3digo postal inv\xE1lido': 'Invalid postal code',
    DV: 'Check digit',
    'Dados de antecipa\xE7\xE3o': 'Advancement settings',
    'Dados enviados com sucesso.': 'Data sent successfully.',
    'Dados salvos com sucesso.': 'Data saved successfully.',
    'Data de origem': 'Initial date',
    'Data de início': 'Start date',
    'Data de recebimento': 'Reciving date',
    'Data de vencimento': 'Due date',
    Deletar: 'Delete',
    'Desc.': 'Desc.',
    'Dia de recebimento': 'Day of the month to receive',
    'Dia do recebimento': 'Receiving day',
    daysForRepaymentOfTheFirstInstallment: 'Days of transfer of the first installment',
    transferDays: 'Transfer days (other installments)',
    'Dias de repasse da primeira parcela': 'Days of transfer of the first installment',
    'Digite o nome do creditor': 'Enter the name of the creditor',
    'Digite seu convenio': 'Enter your covenant',
    'Digite sua ag\xEAncia': 'Enter your agency',
    'Digite sua conta corrente': 'Enter your checking account',
    'Digite uma senha com pelo menos 6 caracteres': 'Enter a password with at least 6 characters',
    'Digito verificador': 'Verifying digit',
    Diário: 'Daily',
    'E-mail': 'E-mail',
    'E-mail inv\xE1lido': 'Invalid email',
    'E-mail inv\xE1lido.': 'Invalid email.',
    Ecommerce: 'Ecommerce',
    Endereço: 'Address line 1',
    'Erro inesperado. Por favor tente mais tarde.': 'Unexpected error. Please try again later.',
    'Erro!': 'Error!',
    'Escolha a \xE1rea de neg\xF3cio.': 'Choose the business area.',
    'Escolha o Gateway': 'Choose the Gateway',
    'Escolha o cedente': 'Choose a bank',
    'Escolha o tipo de programa.': 'Choose the type of program.',
    'Escolha um banco': 'Choose a bank',
    'Escolha um banco cedente': 'Choose a transferor bank',
    'Escolha um banco cedente.': 'Choose a transferor bank.',
    'Escolha um credor': 'Choose a lender',
    'Escolha um nome para o novo cedente.': 'Choose a name for the new assignor.',
    Espécie: 'Specie',
    'Esp\xE9cie Documento': 'Specie Document',
    'Essa transa\xE7\xE3o nao possui detalhes': 'This transaction has no details',
    'Esta conta est\xE1 vinculada \xE0 um {0} e n\xE3o pode ser alterada.': function (a) {
      return ['This account is linked to a ', a('0'), ' and cannot be changed.']
    },
    Estabelecimentos: 'Sellers',
    Estado: 'State',
    'Exibir apenas estabelecimentos vinculados': 'Filter linked merchants',
    'Falha na socicita\xE7\xE3o. Por favor, tente novamente.': 'Socitation failed. Please try again.',
    'Falha no envio dos dados. Por favor, tente novamente mais tarde': 'Failed to send data. Please try again later',
    'Falha!': 'Failure!',
    Fechar: 'Close',
    Filtrar: 'Filter',
    mdrTransferForm: 'MDR transfer form',
    transferFormOfTheTransaction: 'Transaction transfer form',
    'Forma de repasse do MDR': 'MDR transfer form',
    'Formato n\xE3o suportado.': 'Unsupported format.',
    'ID da conta': 'Account ID',
    'ID de autoriza\xE7\xE3o': 'Authorization ID',
    Id: 'Id',
    'Id Maxipago': 'Id Maxipago',
    'Id da conta': 'Account Id',
    'Informe o bairro.': 'Inform the neighborhood.',
    'Informe o endere\xE7o.': 'Enter the address.',
    'Informe o nome da empresa.': 'Enter the company name.',
    'Informe o n\xFAmero.': 'Enter the number.',
    'Inscri\xE7\xE3o Municipal': 'Municipal registration',
    'Insira seu nome.': 'Enter your name.',
    'Insira um nome': 'Enter a name',
    Instruções: 'Instructions',
    Integral: 'Full',
    'Item deletado com sucesso.': 'Item successfully deleted.',
    Liquidado: 'Settled',
    Local: 'Local',
    Logradouro: 'Address',
    MDR: 'MDR',
    Marketplace: 'Marketplace',
    Mensal: 'Monthly',
    Moeda: 'Coin',
    'M\xE9todo de cr\xE9dito': 'Crediting method',
    'Nenhum item encontrado.': 'No items found.',
    'Nenhum resultado encontrado. Tente fazer outra pesquisa.': 'No results found. Try another search.',
    Nome: 'Name',
    'Nome Fantasia': 'Trade name',
    'Nome da Empresa': 'Company Name',
    operationName: 'Operation Name',
    'Nome da marca': 'Brand name',
    'Nome do Marketplace': 'Marketplace Name',
    'Nome do contato': 'Name of contact person',
    creditorName: 'Creditor name',
    establishmentName: 'Seller name',
    'Nome do m\xE9dico': "Doctor's name",
    'Nome do novo cedente': 'Name of the new assignor',
    'Novo Cedente': 'New Seller Bank',
    'Novo Credor': 'New Lender',
    'Novo Marketplace': 'New Marketplace',
    'Novo cedente': 'New seller bank',
    'Novo credor': 'New creditor',
    'Novo estabelecimento': 'New seller',
    documentNumber: 'Document number',
    'N\xE3o Existem transa\xE7\xF5es para a data iniciada em: {initialDateText}': function (a) {
      return ['There are no transactions for printing data at: ', a('initialDateText')]
    },
    'N\xE3o Existem transa\xE7\xF5es para o perido entre: {initialDateText} - {endDateText}': function (a) {
      return ['There are no transactions for the period between: ', a('initialDateText'), ' - ', a('endDateText')]
    },
    'N\xE3o informado': 'Uninformed',
    'N\xE3o possui taxas e tarifas cadastradas': 'No registered fees and taxes',
    Número: 'Number',
    'N\xFAmero de documento inv\xE1lido.': 'Invalid document number.',
    'N\xFAmero de telefone inv\xE1lido.': 'Invalid phone number.',
    'N\xFAmero do documento': 'Document number',
    'N\xFAmero do terminal POS': 'POS terminal number',
    'O c\xF3digo do seu estabelecimento na plataforma PISMO \xE9:':
      'The code of your establishment on the PISMO platform is:',
    Observações: 'Comments',
    'Ocorreu um erro no servidor. Tente novamente em alguns instantes.':
      'A server error has occurred. Try again in a few moments.',
    Operação: 'Transaction type',
    operations: 'Transaction types',
    'Ou para o modelo de liquida\xE7\xE3o CNAB, por favor selecione um creditor existente':
      'In case of CNAB settlement operation, please select a previously created creditor',
    'Ou para o modelo de liquida\xE7\xE3o digital, por favor crie um novo creditor':
      'In case of digital settlement operation, please create a new creditor',
    PDV: 'Point of sales',
    'PORCENTAGEM RAV': 'ADVANCEMENT PERCENTAGE',
    POS: 'POS',
    'Pagamento obrigat\xF3rio? (Aceite)': 'Mandatory payment? (Accepted)',
    Pago: 'Paid out',
    installments: 'Installment',
    Parcelas: 'Installments',
    País: 'Country',
    Pendente: 'Pending',
    allowCardlessTransactions: 'Allow cardless transactions',
    allowTransactionsOnlyWithDoc: 'Allow transactions using document number only',
    'Periodicidade de Liquida\xE7\xE3o': 'Settlement Frequency',
    'Pesquise por usu\xE1rio (e-mail)': 'Search by user (email)',
    'Pesquise um Marketplace': 'Search a Marketplace',
    'Pesquise um cedente': 'Search for a seller bank',
    'Pesquise um credor': 'Search for a creditor',
    'Pesquise um estabelecimento': 'Search for a seller',
    'Por favor selecione uma data': 'Please select a date',
    'Por favor, Digite a chave Maxipago': 'Please enter the Maxipago key',
    'Por favor, Digite a cidade': 'Please enter the city',
    'Por favor, Digite a inscri\xE7\xE3o municipal': 'Please enter the municipal registration',
    'Por favor, Digite o DDD telefone': 'Please enter the phone number',
    'Por favor, Digite o estado': 'Please enter the state',
    'Por favor, Digite o id': 'Please enter the id',
    'Por favor, Digite o nome do seu bairro': 'Please enter the name of your neighborhood',
    'Por favor, Digite o nome fantasia': 'Please enter your fancy name',
    'Por favor, Digite o nome raz\xE3o social': 'Please enter the Company name',
    'Por favor, Digite o numero do Logradouro': 'Please enter the street number',
    'Por favor, Digite o pa\xEDs': 'Please Enter Country',
    'Por favor, Digite seu cep': 'Please enter your zip code',
    'Por favor, Digite seu endere\xE7o': 'Please enter your address',
    'Por favor, Digite seu numero de documento': 'Please enter your document number',
    'Por favor, Digite um nome contato': 'Please enter a contact name',
    'Por favor, Digite um numero correspondente a dias de repasse a demais parcelas':
      'Please, enter a number corresponding to days of transfer to other installments',
    'Por favor, Digite um numero correspondente a dias de repasse da primeira parcela':
      'Please, enter a number corresponding to days of transfer of the first installment',
    selectAnOption: 'Please select an option',
    'Por favor, digite o numero': 'Please enter the number',
    'Por favor, digite o numero da agencia': 'Please enter the agency number',
    'Por favor, digite o numero da conta': 'Please enter your account number',
    'Por favor, preencha o campo nome': 'Please fill in the name field',
    'Por favor, selecione o tipo da conta.': 'Please select your account type.',
    'Por favor, selecione um banco': 'Please select a bank',
    'Por favor, selecione um banco cedente': 'Please select a transferor bank',
    'Por favor, selecione uma op\xE7\xE3o': 'Please select an option',
    'Porcentagem M\xE1x de antecipa\xE7\xE3o': 'Max advancement percentage',
    'Porcentagem m\xE1x. de antecipa\xE7\xE3o': 'Max percentage advancement',
    Quantidade: 'Amount',
    'Quarta-feira': 'Wednesday',
    'Quinta-feira': 'Thursday',
    Quinzenal: 'Biweekly',
    'Raz\xE3o Social': 'Company name',
    Rejeitado: 'Rejected',
    Repasse: 'Transfer',
    'Repasse MDR': 'Transfer MDR',
    'Repasse da transa\xE7\xE3o': 'Transaction pass-through',
    'Repita a senha': 'repeat the password',
    'Requisi\xE7\xE3o n\xE3o efetuada. Por favor tente mais tarde': 'Request not made. Please try again later',
    Salvar: 'Save',
    'Segunda-feira': 'Monday',
    'Selecionar um creditor existente': 'Select an existing creditor',
    'Selecione a data': 'Select a date',
    'Selecione a opera\xE7\xE3o': 'Select the transaction type',
    'Selecione ao menos uma regra:': 'Select at least one rule:',
    'Selecione o Status': 'Select the Status',
    'Selecione um Marketplace': 'Select a Marketplace',
    'Selecione um Seller': 'Select a Seller',
    Semanal: 'Weekly',
    successfullyRegisteredData: 'Your data has been successfully registered.',
    'Seus dados foram salvos com sucesso.': 'Your data has been successfully saved.',
    'Sexta-feira': 'Friday',
    'Status alterado com sucesso.': 'Status changed successfully.',
    'Status do processo': 'Workflow status',
    'Status registrado.': 'Registered status.',
    'Suas altera\xE7\xF5es foram registradas com sucesso.': 'Your changes have been successfully registered.',
    'Sucesso!': 'Success!',
    'TARIFA RAV': 'ADVANCEMENT RATE',
    'TAXA RAV': 'ADVANCEMENT RATE',
    'Tarifa de Antecipa\xE7\xE3o': 'Advancement Fee (fixed value)',
    'Tarifa de antecipa\xE7\xE3o': 'Advancement fee',
    'Tarifa por transa\xE7\xE3o': 'Transaction fee',
    'Taxa de desconto': 'Discount rate (percentage)',
    transitionFee: 'Transaction fee',
    'Taxa de transi\xE7\xE3o': 'Transition rate',
    'Taxas e tarifas relacionadas ao marketplace n\xE3o foram encontradas.':
      'Marketplace-related fees and charges were not found.',
    Telefone: 'Phone',
    'Telefone Adicional (opcional)': 'Additional phone (optional)',
    'Telefone II (opcional)': 'Phone II (optional)',
    'Telefone principal': 'Primary phone',
    'Tem certeza que deseja alterar o status?': 'Are you sure you want to change the status?',
    'Tem certeza que deseja remover este item?': 'Are you sure you want to remove this item?',
    'Ter\xE7a-feira': 'Tuesday',
    'Tipo de conta': 'Account Type',
    'Tipo de opera\xE7\xE3o': 'Operation type',
    'Todos os estabelecimentos': 'All sellers',
    'Total a pagar': 'Total due',
    'Tipo da Conta para Liquida\xE7\xE3o': 'Account Type for Settlement',
    'Use este n\xFAnero para as configura\xE7\xF5es do seu POS ou PDV.':
      'Use this number for your POS or PDV settings.',
    Usuários: 'Users',
    'Vence em': 'Wins on',
    'Vincular conta a um Marketplace': 'Link account to a Marketplace',
    'Vincular conta a um Seller': 'Link account to a Seller',
    'Voc\xEA n\xE3o tem permiss\xE3o para acessar esta \xE1rea.': 'You are not allowed to access this area.',
    Webpos: 'Webpos',
    'Workflow status': 'Workflow status',
    'a pagar': 'to pay',
    agenda: 'Schedule',
    'agenda a receber': 'Schedule',
    'autoriza\xE7\xE3o: {0}': 'authorization',
    bruto: 'Gross amount',
    cancelar: 'cancel',
    condições: 'Creditor Operations',
    confirmar: 'confirm',
    confirmação: 'confirmation',
    continuar: 'continue',
    'c\xF3digo postal': 'Postal code',
    data: 'Date',
    'data do recebimento': 'Receiving date',
    'data final': 'Due date',
    'data inicial': 'Initial date',
    'data origem': 'Origin date',
    'data recebimento': 'Receiving date',
    'data vencimento': 'Due date',
    deletar: 'Delete',
    desconto: 'Tax',
    'desconto antecipa\xE7\xE3o': 'Advancement fee',
    'desconto taxa': 'Tax charge',
    desvincular: 'Unlink',
    download: 'Download',
    'e-mail': 'e-mail',
    'elo cr\xE9dito': 'elo credit',
    'elo d\xE9bito': 'elo debit',
    enviar: 'submit',
    estabelecimentos: 'Sellers',
    'hiper cr\xE9dito': 'hiper credit',
    'hiper d\xE9bito': 'hiper debit',
    home: 'Home',
    'id de autoriza\xE7\xE3o': 'authorization id',
    incluir: 'include',
    líquido: 'Net value',
    liquidoAReceber: 'Net receivable',
    moeda: 'currency',
    nome: 'name',
    novo: 'new',
    'n\xFAmero do documento': 'Document number',
    observações: 'comments',
    operação: 'Transaction type',
    outros: 'other types',
    país: 'country',
    próximo: 'next',
    voltar: 'back',
    receita: 'income',
    remover: 'remove',
    salvar: 'save',
    rotuloDoCampoDeSenha: 'password',
    status: 'Status',
    'status do processo': 'Workflow status',
    'taxas / tarifas': 'Fees / Taxes',
    antecipacao: 'Anticipation',
    tipo: 'Transaction type',
    'tipo do programa': 'Program',
    transações: 'Transactions',
    'valor bruto': 'Gross amount',
    'valor l\xEDquido': 'Net value',
    'valor recebido': 'Amount received',
    vincular: 'link',
    'N\xdamero do documento de identifica\xE7\xE3o do estabelecimento comercial':
      'Seller Documentation Identification Number',
    'Programa do tipo merchant (criado previamente)': 'Merchant type program (previously created)',
    '\xe1rea de neg\xf3cio conforme tabela previamente cadastrada':
      'Business area according to previously registered table',
    'Nome do Estabelecimento': 'Seller Name',
    'O tipo de conta pode ser interno ou externa':
      'The type of account can be internal (Pismo platform account) or external (External Bank account)',
    'Periodicidade de liquida\xE7\xE3o que pode ser configurada de di\xe1ria a mensal':
      'Settlement frequency that can be configured from daily to monthly',
    'A taxa de antecipa\xE7\xE3o pode ser Fixa, Percentual ou a combina\xE7\xE3o de ambos.':
      'The advance rate can be Fixed, Percentage or a combination of both.',
    'A taxa de desconto pode ser Fixa, Percentual ou a combina\xE7\xE3o de ambos.':
      'The discount rate can be Fixed, Percentage or a combination of both.',
    'Porcentagem m\xe1xima que pode ser antecipada pelo estabelecimento':
      'Maximum percentage that can be advanced by the seller',
    '{0,date,date0}': function (a) {
      return [a('0', 'date', 'date0')]
    },
    '{0,number,number0}': function (a) {
      return [a('0', 'number', 'number0')]
    },
    '{item,number,number0}': function (a) {
      return [a('item', 'number', 'number0')]
    },
    '{res}': function (a) {
      return [a('res')]
    },
    '\xC1rea de neg\xF3cio': 'Business area',
    Detalhes: 'Details',
    sair: 'Logout',
    TotalARreceber: 'Total to receive',
    ValorAAntecipar: 'Value to anticipate',
    LiquidoAReceber: 'Total net receivable',
    SolicitarAntecipacao: 'Request advance',
    AntecipacaoRealizadaComSucesso: 'Anticipation done successfully',
    NaoExistemAntecipacoesRalizaveis: 'There are no advances to be made',
    Antecipacao: 'Anticipation',
    juros: 'fees',
    mes: 'month',
    Tarifa: 'Tariff',
    TaxaEfetivaCET: 'CET effective rate',
    ConfirmarAntecipacao: 'Confirm anticipation',
    ValorLiquidoAReceber: 'Net amount receivable',
    datasAntecipacao: 'Dates available to book in advance',
    selecionePeriodoAntecipacao:
      'Select the desired period using the selection filter above, or click directly on the chart items',
    chartDateFormat: 'MM/dd/yy',
    total: 'Total',
    selecionePeriodo: 'Select the desired period',
    transactionWithoutDetails: 'This transaction has no details',
    transactionsOfTheDay: 'Transactions on',
    noTransactions: 'There are no transactions for the selected day',
    selectPeriod: 'Select period',
    selectSeller: 'Select a Seller',
    genericApiError: 'A server error has occurred. Try again in a few moments.',
    mustHaveAtLeastOneEstablishmentLinked: 'The marketplace must have at least one establishment linked to it.',
    selectAnEstablishmentToViewTransactions: 'Select an establishment to view transactions:',
    dataSuccessfullySaved: 'Data successfully saved',
    itemDeletedSuccessfully: 'Item deleted successfully',
    accountTypeForSettlement: 'Account type for settlement',
    platformAccount: 'Platform account',
  },
}

export default en
